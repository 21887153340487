/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '../redux/hooks';
import { AuthState } from 'redux/features/authSlice';

const useDispatchQueueCallSocket = (
  onMessage: (data: any) => void = () => {}
) => {
  const auth = useAppSelector(state => state.auth) as AuthState;
  const [messages, setMessages] = useState<any[]>([]);
  const [call, setCall] = useState<any>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const isConnected = useRef<boolean>(false);

  useEffect(() => {
    const connectWebSocket = () => {
      const socketUrl = `${process.env.REACT_APP_SOCKET_DEV}/ws/dispatcher-calls/subscribe/?token=${auth.token}`;
      if (
        !auth.token ||
        auth?.user?.user_type !== 'DS' ||
        !auth?.user?.accept_calls
      )
        return; // Only connect for DS users

      if (!isConnected.current) {
        wsRef.current = new WebSocket(socketUrl);
        isConnected.current = true;

        wsRef.current.onopen = () => {
          console.log('WebSocket connected');
        };

        wsRef.current.onmessage = e => {
          const data = JSON.parse(e.data);
          setCall(data);
          setMessages(prevMessages => [...prevMessages, data]);
          onMessage?.(data);
        };

        wsRef.current.onerror = error => {
          console.error('WebSocket error:', error);
          isConnected.current = false;
        };

        wsRef.current.onclose = () => {
          console.log('WebSocket disconnected');
          isConnected.current = false;
        };
      }
    };

    if (!wsRef.current && auth.token) {
      connectWebSocket();
    }

    // Clean up WebSocket on unmount
    return () => {
      try {
        if (wsRef.current) {
          wsRef.current.close();
          isConnected.current = false; // Reset connection status on close
          wsRef.current = null;
          console.log('WebSocket closed on component unmount');
        }
      } catch (e) {
        console.warn(e);
      }
    };
  }, [auth]);

  return { messages, call };
};

export default useDispatchQueueCallSocket;
