// import Badge from 'components/base/Badge';
import EcomTotalOrdersChart from './EcomTotalOrdersChart';
import React from 'react';
import { Card } from 'react-bootstrap';
import { totalCallTimeProps } from '../ecommerce/dashboard.interface';
import Loader from 'components/loader';

interface ecomTotalOrdersCardProps {
  data: totalCallTimeProps | undefined;
  isLoading: boolean;
}

const EcomTotalOrdersCard = ({ data, isLoading }: ecomTotalOrdersCardProps) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-1">
              Total calls
              {/* <Badge bg="warning" variant="phoenix" pill className="ms-2">
                -6.8%
              </Badge> */}
            </h5>
            <h6 className="text-body-tertiary">Last 7 days</h6>
          </div>
          <h4>{data?.total_minutes ?? 0}&nbsp; min</h4>
        </div>
        <div className="d-flex justify-content-center px-4 py-6">
          <EcomTotalOrdersChart data={data?.daily_call_counts} />
        </div>
        <div className="mt-2">
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">
              Longer than 1 minute
            </h6>
            <h6 className="text-body fw-semibold mb-0">
              {data?.longer_percentage ?? 0}%
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-primary-subtle me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">
              Shorter than 1 minute
            </h6>
            <h6 className="text-body fw-semibold mb-0">
              {data?.shorter_percentage ?? 0}%
            </h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default EcomTotalOrdersCard;
