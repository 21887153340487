import BackdropLoader from 'components/backdrop-loader';
import React, { useState, useEffect } from 'react';

type StyleProps = React.CSSProperties;

interface Props {
  delay?: number;
  backdrop?: boolean;
  onBody?: boolean;
  style?: StyleProps;
}
const Loader = ({
  delay = 0,
  backdrop = false,
  onBody = false,
  style = {}
}: Props) => {
  const [show, setShow] = useState(delay ? false : true);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, delay);
  }, [delay]);
  return (
    <>
      {show && (
        <BackdropLoader onBody={onBody} backdrop={backdrop} style={style} />
      )}
    </>
  );
};

export default Loader;
