import { Col, Form, Row } from 'react-bootstrap';
import EcomTotalSellsChart from './EcomTotalSellsChart';
import useEcomTotalSellsHook from './useEcomTotalSellsHook';
import useDashboardHook from '../ecommerce/useDashboardHook';
import Loader from 'components/loader';
import React, { useState, useEffect } from 'react';

interface EcomTotalSellsProps {
  monthList: string[];
}

const EcomTotalSells = ({ monthList }: EcomTotalSellsProps) => {
  const {
    getMonthStartEndDates,
    setSelectChartMonth,
    selectChartMonth,
    getMonthDates
  } = useEcomTotalSellsHook();
  const chartDates = getMonthDates(selectChartMonth);
  const { calls, isLoadingCalls } = useDashboardHook(chartDates);
  console.info(
    'selectChartMonth, monthList, init month',
    selectChartMonth,
    // monthList,
    monthList?.[0]
  );

  const [reverseMonthList, setReverseMonthList] = useState([]);
  React.useEffect(() => {
    if (monthList?.length) {
      const monthListT = JSON.parse(JSON.stringify(monthList));
      monthListT.reverse();
      setReverseMonthList(monthListT);
    }
  }, [monthList]);
  return (
    <>
      <Row className="justify-content-between align-items-center mb-4 g-3">
        <Col xs="auto">
          <h3>Total call time</h3>
          <p className="text-body-tertiary lh-sm mb-0">
            Summary of all users call time in minutes
          </p>
        </Col>
        <Col xs={8} sm={4}>
          {reverseMonthList?.length > 0 && (
            <>
              <Form.Select
                size="sm"
                onChange={e => setSelectChartMonth(e.target.value)}
                value={
                  selectChartMonth ? selectChartMonth : reverseMonthList?.[0]
                }
              >
                {reverseMonthList?.map(month => (
                  <option
                    key={month}
                    value={month}
                    // selected={month === getMonthStartEndDates()}
                  >
                    {month}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
        </Col>
      </Row>
      {isLoadingCalls ? (
        <Loader style={{ height: '316px' }} />
      ) : (
        <EcomTotalSellsChart
          selectedData={selectChartMonth}
          totalCalls={calls}
        />
      )}
    </>
  );
};

export default EcomTotalSells;
