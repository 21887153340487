/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';

const useEcomTotalSellsHook = () => {
  const [selectChartMonth, setSelectChartMonth] = useState<string>('');

  function getMonthStartEndDates() {
    // Get the current date
    const currentDate = new Date();

    // Get the current year and month
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth(); // 0-indexed, so January is 0, June is 5, etc.

    // Create a new date for the first day of the current month
    const startDate = new Date(year, month, 1);
    // Create a new date for the last day of the current month
    const endDate = new Date(year, month + 1, 0);

    // Define month names array
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    // Get the month name from the array
    const monthName = monthNames[startDate.getMonth()];

    // Format the dates in the desired format: Jun 1, 30 2024
    const formattedStartDate = `${monthName} ${startDate.getDate()}`;
    const formattedEndDate = `${endDate.getDate()} ${year}`;

    return `${formattedStartDate}, ${formattedEndDate}`;
  }

  // Helper function to format the date as MM/DD/YYYY
  function formatDate(date: Date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  function getMonthStartEnd(dateStr: string) {
    // Parse the input date string
    const date = new Date(dateStr);

    // Get the start date of the month
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);

    // Get the end date of the month
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // Format the dates
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options: any = { month: 'short', day: 'numeric', year: 'numeric' };
    const startStr = startDate.toLocaleDateString('en-US', options);
    const endStr = endDate.toLocaleDateString('en-US', options);

    // Extract and format the desired parts
    const startFormatted = `${startStr.split(' ')[0]} ${
      startStr.split(' ')[1]
    }`;
    const endFormatted = `${endStr.split(' ')[1].replace(',', '')}`;

    return `${startFormatted} ${endFormatted} ${date.getFullYear().toString()}`;
  }

  function getMonthDates(input: string) {
    // Parse the input string (example: "May 1, 31 2024")
    if (!input) {
      input = formatDate(new Date());
      input = getMonthStartEnd(input);
    }
    // const [monthName, startDay, endDay, year] = input.split(' ');
    let monthName, startDay, endDay, year;
    const dateSplit = input.split(' ');
    if (dateSplit?.length === 2) {
      monthName = dateSplit?.[0];
      year = dateSplit?.[1];
    } else {
      monthName = dateSplit?.[0];
      year = dateSplit?.[dateSplit?.length - 1];
    }
    if (monthName) {
      monthName = monthName?.replaceAll(',', '');
    }
    // Define month names array
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const monthIndex = monthNames.indexOf(monthName);

    // Current month start and end dates
    const currentStartDate = new Date(parseInt(year), monthIndex, 1);
    const currentEndDate = new Date(parseInt(year), monthIndex + 1, 0);

    // Previous month start and end dates
    const previousStartDate = new Date(parseInt(year), monthIndex - 1, 1);
    const previousEndDate = new Date(parseInt(year), monthIndex, 0);

    return {
      currentStartDate: formatDate(currentStartDate),
      currentEndDate: formatDate(currentEndDate),
      previousStartDate: formatDate(previousStartDate),
      previousEndDate: formatDate(previousEndDate)
    };
  }

  return {
    getMonthStartEndDates,
    setSelectChartMonth,
    selectChartMonth,
    getMonthDates
  };
};

export default useEcomTotalSellsHook;
