import { useState, useEffect } from 'react';

type NotificationPermissionState = 'granted' | 'denied' | 'default';

const useNotification = () => {
  const [permission, setPermission] = useState<NotificationPermissionState>(
    Notification.permission
  );

  // Request notification permission from the user
  const requestPermission = () => {
    if (
      Notification.permission !== 'granted' &&
      Notification.permission !== 'denied'
    ) {
      Notification.requestPermission().then(newPermission => {
        setPermission(newPermission);
      });
    }
  };

  // Function to send a notification if the window is not active (not in focus)
  const sendNotification = (
    title: string,
    options?: NotificationOptions,
    callback?: () => void
  ) => {
    if (permission === 'granted' && document.hidden) {
      const notification = new Notification(title, options);

      // Add an onclick event to focus on the tab when notification is clicked
      notification.onclick = () => {
        window.focus(); // Focus on the current tab
        notification.close(); // Close the notification
        if (callback) {
          callback?.();
        }
      };
    } else if (!document.hidden) {
      console.log('The window is currently active, no notification sent.');
    } else {
      console.log('Notification permission is not granted.');
    }
  };

  // Check for permission change
  useEffect(() => {
    setPermission(Notification.permission);
  }, []);

  return { permission, requestPermission, sendNotification };
};

export default useNotification;
