/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from 'react';
import { AuthUserState } from '../../../redux/features/authSlice';
import { useAppSelector } from '../../../redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'common/router-name';
import useNotification from 'hooks/useNotification';
import useDispatchQueueCallSocket from 'hooks/useDispatchQueueCallSocket';
const CallNotification: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const authUser = useAppSelector(state => state.auth.user) as AuthUserState;
  const { requestPermission, sendNotification } = useNotification();

  const { call } = useDispatchQueueCallSocket();
  useEffect(() => {
    requestPermission();
  }, []);

  const hasNotification =
    authUser.user_type === 'DS' &&
    (call?.type === 'existing_calls' || call?.type === 'new_call') &&
    call?.call_data &&
    authUser.accept_calls &&
    pathname !== Routes.PendingUserQue;

  useEffect(() => {
    if (hasNotification && call?.type === 'new_call') {
      sendNotification(
        'New Notification!',
        {
          body: 'URGENT INCOMING CALL.',
          icon: `${window.location.origin}/favicon.png` // Optional: icon URL
        },
        () => {
          navigate(Routes.PendingUserQue);
        }
      );
    }
  }, [hasNotification, call]);
  return (
    <>
      {hasNotification && (
        <p
          className="p-3 fw-semibold cursor-pointer"
          style={{
            color: '#ffffff',
            backgroundColor: '#F48B8B',
            marginTop: '-32px',
            marginLeft: '-40px',
            marginRight: '-40px',
            textAlign: 'center',
            transition: '1s'
          }}
          onClick={() => navigate(Routes.PendingUserQue)}
        >
          INCOMING CALL - YOU WILL BE REDIRECTED TO THE USER
        </p>
      )}
      {/* <div>
        <h2>WebSocket Chat (TypeScript)</h2>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            height: '200px',
            overflowY: 'scroll'
          }}
        >
          <h4>Messages:</h4>
          {messages.length > 0 ? (
            messages.map((msg, index) => <div key={index}>{msg}</div>)
          ) : (
            <p>No messages yet...</p>
          )}
        </div>
      </div> */}
    </>
  );
};

export default CallNotification;
