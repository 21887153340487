import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authSlice from './features/authSlice';
import dsCallSlice from './features/dsCallSlice';
import dsJoinCallSlice from './features/dsJoinCallSlice';
import sessionClose from './features/sessionClose';

const rootReducer = combineReducers({
  auth: authSlice,
  dsCall: dsCallSlice,
  dsJoinCall: dsJoinCallSlice,
  sessionClose: sessionClose
});

const makeStore = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: {
    name: 'Crimesly Main App Instance' // Custom name for Redux DevTools
  }
});

// Infer the type of makeStore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppStore = ReturnType<any>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export const storeData = makeStore.getState();

export default makeStore;
