/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import Cookies from "js-cookie";
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  urls: Window[] | any;
} = {
  urls: localStorage.getItem('active_session')
    ? [localStorage.getItem('active_session')]
    : []
};

export const initialSession = createAsyncThunk(
  'sessionClose/initialSession',
  async (url: Window | null) => {
    return url;
  }
);

export const initialSessionClose = createAsyncThunk(
  'sessionClose/initialSessionClose',
  async () => {
    return [];
  }
);

export const sessionClose = createSlice({
  name: 'sessionClose',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(initialSession.pending, state => {
        state.urls = [];
        return state;
      })
      .addCase(
        initialSession.fulfilled,
        (state, action: PayloadAction<Window | any>) => {
          if (action.payload) {
            localStorage.setItem('active_session', action.payload);
            state.urls.push(action.payload);
          }
        }
      )
      .addCase(initialSession.rejected, state => {
        state.urls = [];
        localStorage.removeItem('active_session');
        return state;
      })
      .addCase(initialSessionClose.pending, state => {
        state.urls = [];
        return state;
      })
      .addCase(initialSessionClose.fulfilled, state => {
        state.urls = [];
        localStorage.removeItem('active_session');
        return state;
      })
      .addCase(initialSessionClose.rejected, state => {
        state.urls = [];
        localStorage.removeItem('active_session');
        return state;
      });
  }
});

export default sessionClose.reducer;
