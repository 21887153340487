import React, {
  Children,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  useState,
  useEffect
} from 'react';
import SearchBox, { SearchBoxProps } from './SearchBox';
import { Dropdown } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Routes } from 'common/router-name';

interface DropdownSearchBoxProps extends SearchBoxProps {
  className?: string;
  searchBoxClassName?: string;
}

const DropdownSearchBox = ({
  children,
  className,
  searchBoxClassName,
  ...rest
}: PropsWithChildren<DropdownSearchBoxProps>) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { pathname, search } = useLocation();
  const [searchInputValue, setSearchInputValue] = useState('');

  useEffect(() => {
    const isSearchPage = pathname === '/search';
    const params = new URLSearchParams(search);
    const q = params.get('q'); // Replace 'name' with your query param
    if (isSearchPage && q) {
      setSearchInputValue(q);
    }
  }, [pathname, search]);

  const navigate = useNavigate(); // useNavigate hook for React Router v6

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Perform the redirect with query parameters
      event.preventDefault();
      navigate(
        `${Routes.Search}?q=${encodeURIComponent(searchInputValue.trim())}`
      );
      setSearchInputValue('');
    }
  };

  return (
    <Dropdown
      className={className}
      onToggle={() => setOpenDropdown(!openDropdown)}
    >
      <Dropdown.Toggle as="div" className="toggle" bsPrefix="toggle">
        <SearchBox
          placeholder="Search..."
          className={searchBoxClassName}
          value={searchInputValue}
          onChange={({ target }) => {
            setSearchInputValue(target.value);
          }}
          onKeyDown={handleKeyDown}
          {...rest}
        />
      </Dropdown.Toggle>
      {children && (
        <Dropdown.Menu
          className="dropdown-menu border font-base start-0 py-0 overflow-hidden w-100"
          style={{ width: 400 }}
        >
          {Children.map(children, child =>
            cloneElement(child as ReactElement, {
              searchValue: searchInputValue
            })
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default DropdownSearchBox;
