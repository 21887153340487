/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import Cookies from "js-cookie";
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  call_id: null | string;
} = {
  call_id: localStorage.getItem('end_call') ?? null
};

export const dsJoinCallAdd = createAsyncThunk(
  'dsJoinCall/add',
  async (data: any) => {
    return data;
  }
);

export const dsCallHoldOrEnd = createAsyncThunk(
  'dsJoinCall/hodlOrEnd',
  async () => {
    return;
  }
);

export const dsCallEnd = createAsyncThunk(
  'dsJoinCall/callEnd',
  async (call_id: string) => {
    return call_id;
  }
);

export const dsJoinCall = createSlice({
  name: 'dsJoinCall',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: builder => {
    builder
      // .addCase(dsJoinCallAdd.pending, state => {
      //   state.status = 'loading';
      //   state.call_id = null;
      // })
      // .addCase(dsJoinCallAdd.fulfilled, (state, action: PayloadAction<any>) => {
      //   localStorage.setItem('dispatcher_join_call_id', action.payload.call_id);
      //   state.status = 'succeeded';
      //   state.call_id = action.payload.call_id;
      // })
      // .addCase(dsJoinCallAdd.rejected, state => {
      //   state.status = 'failed';
      //   state.call_id = null;
      // })
      // .addCase(dsCallHoldOrEnd.pending, state => {
      //   state.status = 'loading';
      //   state.call_id = null;
      // })
      // .addCase(dsCallHoldOrEnd.fulfilled, state => {
      //   localStorage.removeItem('dispatcher_join_call_id');
      //   state.status = 'succeeded';
      //   state.call_id = null;
      // })
      // .addCase(dsCallHoldOrEnd.rejected, state => {
      //   state.status = 'failed';
      //   state.call_id = null;
      // })
      .addCase(dsCallEnd.pending, state => {
        state.call_id = null;
        return state;
      })
      .addCase(dsCallEnd.fulfilled, (state, action: PayloadAction<string>) => {
        state.call_id = action.payload;
        return state;
      })
      .addCase(dsCallEnd.rejected, state => {
        state.call_id = null;
        return state;
      });
  }
});

export default dsJoinCall.reducer;
