import { Routes } from 'common/router-name';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';

interface ProtectedProps {
  children: React.ReactNode;
}

const Protected: React.FC<ProtectedProps> = ({ children }) => {
  const isLogin = useAppSelector(state => state.auth.isLogin);
  const isLock = useAppSelector(state => state.auth.isLock);
  return isLogin && !isLock ? (
    <>{children}</>
  ) : isLogin && isLock ? (
    <Navigate to={`${Routes.Auth}${Routes.LockPage}`} />
  ) : (
    <Navigate to={`${Routes.Auth}${Routes.SignIn}`} />
  );
};

export default Protected;
