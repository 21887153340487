import { Routes } from 'common/router-name';
import { redirect } from 'react-router-dom';
import Store from '../redux/store';

export const isAuthenticated = async () => {
  const state = Store.getState();
  // const isLock = state.auth.isLock;
  // if (isLock) throw redirect(`${Routes.Auth}${Routes.LockPage}`);
  const isLogin = state.auth.isLogin;
  const { Auth, TwoFASignIn, LockPage } = Routes;
  const pathname = window.location.pathname;
  if (
    isLogin &&
    (pathname !== `${Auth}${TwoFASignIn}` ||
      pathname !== `${Auth}${LockPage}`) &&
    !localStorage.getItem('2fa-email')
  ) {
    throw redirect(Routes.Dashboard_One);
  }
  return null;
};
