/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import Cookies from "js-cookie";
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { loginApi } from '../api/auth';
import { credentialsProps } from 'interfaces/auth.interface';
import { redirect } from 'react-router-dom';
import { Routes } from 'common/router-name';

export interface AuthUserState {
  first_name?: string;
  last_name?: string;
  user_id?: string;
  profile_image?: string | null;
  email?: string;
  role?: string;
  address?: null;
  audio_enabled?: boolean;
  country?: null;
  created_at?: string;
  default_password?: boolean;
  gender?: null;
  is_active?: boolean;
  is_document_uploaded?: boolean;
  is_staff?: boolean;
  language?: string;
  location_sharing_enabled?: boolean;
  notification_enabled?: boolean;
  organization?: string;
  phone_no?: string;
  sound_enabled?: boolean;
  student_id?: null;
  updated_at?: string;
  user_type?: string;
  vibrate_enabled?: boolean;
  video_enabled?: boolean;
  needs_password_reset?: boolean;
  accept_calls?: boolean;
}
export interface LoggedUserInfoState {
  auth: AuthState;
}
export interface AuthState {
  user: AuthUserState | null;
  token: string | null;
  refresh: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  isActive: boolean;
  isLogin: boolean;
  isLock: boolean;
  error: null;
}

const isBrowser = typeof window !== 'undefined';

const authUser = isBrowser && localStorage.getItem('user');
const authToken = isBrowser && localStorage.getItem('token');
const authRefresh = isBrowser && localStorage.getItem('refresh');

const initialState: AuthState = {
  user: authUser && typeof authUser === 'string' ? JSON.parse(authUser) : null,
  token: authToken || null,
  status: 'idle',
  isLogin: isBrowser && !!authToken,
  isLock: false,
  isActive: false,
  error: null,
  refresh: authRefresh || null
};

export const loginEvent = createAsyncThunk('auth/login', async (data: any) => {
  return data;
});

export const lockEvent = createAsyncThunk(
  'auth/lock',
  async (credentials: credentialsProps) => {
    try {
      const data = await loginApi(credentials);
      return data;
    } catch (error: any) {
      return error;
    }
  }
);

export const authUpdate = createAsyncThunk(
  'auth/update',
  async (info: AuthUserState) => {
    const user = JSON.parse(localStorage.getItem('user') ?? '');
    const updateUser = {
      ...user,
      first_name: info.first_name ?? user?.first_name,
      last_name: info.last_name ?? user?.last_name,
      phone_no: info.phone_no ?? user?.phone_no,
      profile_image: info.profile_image ?? user?.profile_image
    };
    localStorage.setItem('user', JSON.stringify(updateUser));
    return info;
  }
);

export const authUpdateFlag = createAsyncThunk(
  'auth/updateFlag',
  async (info: { accept_calls: boolean }) => {
    const user = JSON.parse(localStorage.getItem('user') ?? '');
    const updateUser = {
      ...user,
      accept_calls: info.accept_calls
    };
    localStorage.setItem('user', JSON.stringify(updateUser));
    return info;
  }
);

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: () => initialState,
    lockScreen: state => {
      state.isLock = true;
    },
    logout: state => {
      state.user = null;
      state.token = null;
      state.status = 'idle';
      state.isLogin = false;
      state.isLock = false;
      state.isActive = false;
      state.error = null;
      state.refresh = null;
      localStorage.clear();
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loginEvent.pending, state => {
        state.status = 'loading';
      })
      .addCase(
        loginEvent.fulfilled,
        (state, action: PayloadAction<AuthState | any>) => {
          const authUser = {
            needs_password_reset: action.payload.user.needs_password_reset,
            first_name: action.payload.user.first_name,
            last_name: action.payload.user.last_name,
            email: action.payload.user.email,
            user_id: action.payload.user.user_id,
            profile_image: action.payload.user.profile_image,
            address: action.payload.address,
            audio_enabled: action.payload.user.audio_enabled,
            country: action.payload.user.country,
            created_at: action.payload.user.created_at,
            default_password: action.payload.user.default_password,
            gender: action.payload.user.gender,
            is_active: action.payload.user.is_active,
            is_document_uploaded: action.payload.user.is_document_uploaded,
            is_staff: action.payload.user.is_staff,
            language: action.payload.user.language,
            location_sharing_enabled:
              action.payload.user.location_sharing_enabled,
            notification_enabled: action.payload.user.notification_enabled,
            organization: action.payload.user.organization,
            phone_no: action.payload.user.phone_no,
            sound_enabled: action.payload.user.sound_enabled,
            student_id: action.payload.user.student_id,
            updated_at: action.payload.user.updated_at,
            user_type: action.payload.user.user_type,
            vibrate_enabled: action.payload.user.vibrate_enabled,
            video_enabled: action.payload.user.video_enabled,
            accept_calls: action.payload.user.accept_calls
          };
          state.status = 'succeeded';
          state.user = authUser;
          state.token = action.payload.token;
          state.isLogin = true;
          state.isLock = false;
          state.isActive = action.payload.is_active;
          state.error = null;
          localStorage.setItem('token', action.payload.token);
          localStorage.setItem('user', JSON.stringify(authUser));
          // state.refresh = action.payload.tokens.refresh.token;
          // localStorage.setItem('refresh', action.payload.tokens.refresh.token);
        }
      )
      .addCase(
        loginEvent.rejected,
        (
          state: { status: string; error: string | null },
          action: PayloadAction<AuthState | any>
        ) => {
          state.status = 'failed';
          state.error = action?.payload?.error;
        }
      )
      .addCase(lockEvent.pending, state => {
        state.isLock = true;
      })
      .addCase(lockEvent.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLock = false;
      })
      .addCase(
        lockEvent.rejected,
        (state, action: PayloadAction<AuthState | any>) => {
          state.isLock = true;
          state.error = action?.payload?.error;
        }
      )
      .addCase(authUpdate.pending, state => {})
      .addCase(authUpdate.fulfilled, (state, action: PayloadAction<any>) => {
        const authUser = {
          first_name: action.payload.first_name ?? state.user?.first_name,
          last_name: action.payload.last_name ?? state.user?.last_name,
          phone_no: action.payload.phone_no ?? state.user?.phone_no,
          profile_image:
            action.payload.profile_image ?? state.user?.profile_image
        };
        state.user = {
          ...state.user,
          ...authUser
        };
      })
      .addCase(
        authUpdate.rejected,
        (state, action: PayloadAction<AuthState | any>) => {
          state.error = action?.payload?.error;
        }
      )
      .addCase(authUpdateFlag.pending, state => {})
      .addCase(
        authUpdateFlag.fulfilled,
        (state, action: PayloadAction<any>) => {
          const authUser = {
            accept_calls: action.payload.accept_calls
          };
          state.user = {
            ...state.user,
            ...authUser
          };
        }
      )
      .addCase(
        authUpdateFlag.rejected,
        (state, action: PayloadAction<AuthState | any>) => {
          state.error = action?.payload?.error;
        }
      );
  }
});

export const { logout, lockScreen } = auth.actions;

export default auth.reducer;
