import { createPortal } from 'react-dom';
import classNames from 'classnames';
import './style.scss';

type StyleProps = React.CSSProperties;

interface Props {
  backdrop?: boolean;
  onBody?: boolean;
  style?: StyleProps;
}
const BackdropLoader = ({
  backdrop = true,
  onBody = true,
  style = {}
}: Props) => {
  const loaderJSX = (
    <div
      className={classNames(
        backdrop ? 'backdrop-loader' : 'non-backdrop-loader',
        onBody && 'on-body'
      )}
      style={style}
    >
      <div className="app-loader" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
  if (onBody) {
    return createPortal(loaderJSX, document.body);
  }
  return <>{loaderJSX}</>;
};

export default BackdropLoader;
