/* eslint-disable @typescript-eslint/no-explicit-any */
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useState } from 'react';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaste,
  // faCopy,
  // faClipboard,
  faShareSquare
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import './style.scss';
interface Props {
  text: string;
  label?: string;
  copiedLabel?: string;
  className?: string;
  showHintLabel?: boolean;
  sizeClass?: string;
  borderWidth?: string;
  showCopyTextInTooltip?: boolean;
}
const CopyShareableLink = ({
  text = '',
  label = 'Copy URL',
  copiedLabel = 'Copied',
  className = '', //'align-items-end',
  showHintLabel = false,
  sizeClass = 'fs-10',
  borderWidth = '1px',
  showCopyTextInTooltip = true
}: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-center',
        'copy-shareable-link',
        className
      )}
      onMouseLeave={() => setCopied(false)}
    >
      <Button
        // className="fs-8 text-body-quaternary p-0"
        variant="phoenix-secondary"
        className={sizeClass}
        size="sm"
        onClick={handleCopy}
        style={{
          borderWidth: borderWidth
        }}
      >
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="ThemeColor" style={{ position: 'fixed' }}>
              {copied ? copiedLabel : label}
              {showCopyTextInTooltip && <>{' ' + text}</>}
            </Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={copied ? faPaste : faShareSquare}
            color={copied ? '#25b003' : '#6c6c6c'}
          />
        </OverlayTrigger>
      </Button>

      {showHintLabel && <label className={'small'}>{'Shareable Link'}</label>}
    </div>
  );
};

export default CopyShareableLink;
